@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 

html,body{
  overflow: hidden;
}
.vanta-bg-container {
  display: flex;
  flex-direction: column; /* Stack the flipbook and buttons vertically */
  justify-content: center;
  align-items: center;
  position: relative; 
  overflow: hidden;
}

/* General styling for flipbook */
/* .demo-book {
  position: relative;
  margin: 20px auto;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f5f5f5;
} */
.stf__wrapper{
  margin-bottom: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
/* Styling individual pages */
.page {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: black;
  margin: 0 auto;  /* Center the page */
  box-sizing: border-box; /* Include padding in width/height */
  max-width: 90vw;  /* Ensure the page doesn't go beyond the viewport on mobile */
  overflow-y: auto; /* Prevent content from overflowing */
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  box-shadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px';

}
.page-text{
  text-align: left;
}
/* Page content */
.page-content {
  text-align: center;
  font-family: 'Arial', sans-serif;
  overflow-y: auto;
}

/* Header of each page */
.page-header {
  font-size: 24px;
  font-weight: bold;
  color: #0d47a1;
  margin-bottom: 20px;
}

/* Page footer with page number */
.page-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 16px;
  color: #555;
}

/* Styling for cover pages */
.page-cover {
  background: rgba(255, 255, 255, 0.10); /* Semi-transparent white background */
  backdrop-filter: blur(5px);
    color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px';

}
/* Styling for images inside pages */
.page-image {
  text-align: center; /* Center the image */
  margin-bottom: 20px; /* Space between image and text */
}

.page-img {
  max-width: 100%; /* Make sure the image is responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Optional: Add rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  box-shadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px';

}

/* Button container styling */
.container {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  align-items: center;
  margin-top: 1px; /* Adds space between the book and buttons */
  gap: 15px;
  width: 100%; /* Ensure the container takes full width */
}

/* Button styles */
.container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0d47a1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.container button:hover {
  background-color: #0a3780;
}

/* Flipbook page count styling */
.container span {
  font-size: 18px;
  color: whitesmoke;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .demo-book {
    width: 90%;       /* Adjust width to fit mobile */
    height: auto;     /* Let height adjust automatically */
    margin: 0 auto;   /* Center the book */
    padding: 10px;    /* Add some padding for spacing */
  }

  .page {
    width: 100%;      /* Ensure page width takes full container */
    height: auto;     /* Adjust height automatically */
    padding: 15px;    /* Add padding for smaller screens */
    box-sizing: border-box; /* Include padding in width/height */
    max-width: 95vw;  /* Adjust the maximum width to prevent overflow */
    overflow-y: auto; /* Prevent content from overflowing */
    margin-top: 0px; 

  }

  .page-content {
    font-size: 16px;  /* Smaller text for mobile */
  }

  .page-header {
    font-size: 20px;  /* Adjust header font size for mobile */
  }
  .page-text{
    text-align: left;
    font-size: 1rem;
  }

  .page-footer {
    font-size: 14px;  /* Adjust footer text for mobile */
  }
  .container{
    margin-top: 11px;
  }

  .container button {
    padding: 5px 10px;  /* Smaller padding */
    font-size: 12px;    /* Smaller font size */
    margin-bottom: 50px;
  }
}