@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: whitesmoke !important;
    border: 0;
    outline: none;
}
span.blog{
    color: red;
}