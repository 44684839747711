

@import url('https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.menu-item {
  font-family: 'Quantico', sans-serif;
}
/* Navbar.css */
.bm-burger-button {
  z-index: 1000;
}


.bm-burger-bars {
  background: #ffffff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #0d47a1;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #ffffff;
}

.bm-item-list a{
  color: #0d47a1;
  padding: 0.3em;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.social-icons {
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  gap: 6px; /* Adds space between the icons */
}
